import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import SignStyle from './Sign_Style.woff'

export const GlobalStyles = createGlobalStyle`


  :focus-visible {
    outline-color : unset;
    outline-style : unset;
    outline-width : 0px;
  }


body {
  padding : 0!important;
}

 html{
  overflow : auto;
  background-color : #212121;
 }

  .background-product-page{
    width : 100%;
    background-image : url(${props => props.ulrBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: soft-light;
    background-attachment: fixed;
  }


 ::-webkit-scrollbar {
   width: 10px;
 }
 
 ::-webkit-scrollbar-track {
   background: #212121;
   
 }
 
 ::-webkit-scrollbar-thumb {
   background-color: #707070;
   border-radius: 20px;
   border: 3px solid #212121;
 }
 
 ::-webkit-scrollbar-thumb:hover {
   background: #909090;
   
 }

 .MuiSkeleton-root{
  background : rgb(51 48 48)!important;
 }

 @media only screen and (min-width : 1920px){
   .MuiSvgIcon-root {
    width : 1vw;
    height : 1vw;
   }

   small{
    font-size : 0.6vw;
   }
 }
 
`;

export const BgTextStyle = styled.div`

@font-face{
  font-family : 'Sign';
  src : url(${SignStyle}) format('woff');
  font-display: swap;
}

font-family : 'Sign', sans-serif;
font-weight : 400;

position : absolute;
font-size : 21vw;
color : white;
width : 90vw;
text-align:center;

@media only screen and (max-width: 786px) {
  font-size : 23vw;
}



`;